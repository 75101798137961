<template>
    <div>
        <van-nav-bar
        title="我的设置"
        left-arrow
        @click-left="onClickLeft"
        />
        <van-cell-group @click="cellBind" style="margin-top:20px;">
            <van-cell title="个人资料" is-link  icon='contact' to="/mine/myinfo"/>
            <van-cell title="修改密码" is-link icon='lock' to="revisepassword"/>
        </van-cell-group>
        <!-- <van-cell-group @click="cellBind" style="margin-top:20px">
            <van-cell title="清空缓存" is-link icon='delete'/>
        </van-cell-group> -->
        <div  class="footBox">
            <van-button type="info" @click="logoutBind">退出账号</van-button>
        </div>
    </div>
</template>
<script>
export default {
  name: 'setup',
  data(){
    return {

    }
  },
  mounted(){

  },
  methods:{
    cellBind(e){
        console.log(e)
    },
    onClickLeft(){
      this.$router.go(-1)
    },
    logoutBind(){
        sessionStorage.removeItem('Authorization');
        sessionStorage.removeItem('username');
        this.$router.push({name:'logon'})
    }
  }
}
</script>
<style scoped>
   .footBox{
    position: fixed;
    bottom: 20px;
    width:100%;
    text-align: center;
   }
   .van-button{
      width:80%;
      height:40px;
      border-radius: 10px;
   }
</style>